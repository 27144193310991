<template>
    <div class="page page--contact">
        <Contact />     
    </div>
</template>


<script>
import Contact from '@/components/Contact.vue'

export default {    
  components: {    
    Contact    
  }  
}
</script>